import LocalizedStrings from "localized-strings";

const strings = new LocalizedStrings({
	ptBr: {
		ogTags: {
			name: "ConnectVet",
		},
		enum: {},
		error: {
			default: "Um erro ocorreu, tente mais tarde.",
			stillLoading: "Ainda Carregando, aguarde.",
		},
		screen: {
			clinics: {
				address: "Endereço",
				services: "Serviços",
				contact: "Contatos",
				buttonLabel: "Fale com a gente!",
			},
			notFound: {
				title: "Página não encontrada",
				helper: "Você será redirecionado para página principal",
			},
			faq: {
				title: "Dúvidas Gerais",
				faqLeft: [
					{
						title: "Vou viajar para fora do país e pretendo levar meu pet! O que faço quanto à vacinação antirrábica?",
						description:`É preciso vacinar seu cão ou gato contra raiva, e após 30 dias levar a uma clínica ou hospital veterinário para coletar sangue para 
						realizar o exame sorológico de titulação de anticorpos neutralizantes para raiva!  O veterinário responsável deverá enviar a amostra coletada para laboratórios cadastrados pelo MAPA para realização desse exame específico.`,
					},
					{
						title: "Como coloco o chip no meu pet?",
						description: `Alguns veterinários são cadastrados para executar esse serviço.  O procedimento é simples e bem rápido, normalmente feito no próprio consultório e não exige sedação.
						O que vale a pena pesquisar é a marca do chip, pois apenas alguns leitores são liberados em aeroportos por exemplo.  Nas regras exigidas pelas empresas aéreas para viagens com pets constam as marcas de leitores mais utilizados.`,
					},
					{
						title: "O que devo saber das empresas aéreas para poder viajar com meu pet?",
						description: `Os Países exigem documentações e períodos de quarentena diferentes, e em algumas empresas aéreas as legislações também podem variar.
						Então o mais recomendado é consultar os sites ou entrar em contato com empresa que irá viajar para saber das regras exigidas por ela e pelo país que deseja visitar.
						`,
					},
					{
						title: "Mitos e verdades sobre vacinas nacionais e importadas.",
						description: `De modo geral, todas as vacinas que usamos são fabricadas no Brasil.
						Algumas empresas fornecem um seguro para seus clientes, isso significa que o produto realmente é de confiança. 
						Para utilizar desse benefício você deve procurar clínicas ou hospitais veterinários que utilizam 
						vacinas que emitem esse selo de segurança em primeiro lugar. Você só será beneficiado se seguir as datas de vacinação de acordo com a idade, quarentenas sem contato com animais não vacinados e períodos exatos entre as doses.
						Caso você esteja com o cartão em dia, e seu pet mesmo assim se contaminar com alguma doença viral 
						que a vacina protege como, por exemplo, parvovirose, o veterinário, além de tratar seu pet, vai providenciar um documento que vai constar todas as informações sobre a confirmação da infecção, cumprimento do protocolo vacinal e tratamento preconizado durante toda a internação.
						Dessa forma a fabricante da vacina irá avaliar esse documento e efetuará o ressarcimento dos custos 
						que você teve com seu pet durante todo o tratamento. Portanto nós indicamos essas vacinas, pois normalmente a empresa que garante o seu produto é porque confia na eficácia do mesmo.
						 `,
					},
				],
				faqRight: [
					{
						title: "Todas as vacinas utilizadas pela clínica garantem esse seguro?",
						description: "Não! As vacinas que fornecem esse seguro são as que usamos contra doenças virais, as chamadas popularmente de V8, V10, importadas, etc.",
					},
					{
						title: "Meu cão foi vacinado para leishmaniose e seguimos corretamente o protocolo prescrito pelo veterinário.  Mas agora ele foi diagnosticado com a doença, por quê? ",
						description: `Existem vacinas contra doenças causadas por vírus, v8, v10, as de felinos e a antirrábica, elas impedem seu pet de adquirir a doença propriamente dita, porque induzem o sistema imunológico a produzir anticorpos de memória para cada antígeno vacinado. 
						Outras vacinas são feitas para diminuir a gravidade dos sintomas de doenças específicas, 
						por exemplo, as vacinas contra protozoários e bactérias que são contra leishmaniose, gripe canina e giardíase. Elas não impedem o pet de adquirir a doença, porém evitam que os sintomas se agravem muito, promovendo, portanto uma barreira de proteção a mais para seu animal.
						No caso da leishmaniose, evitar o mosquito é fundamental, com uso de repelentes pour-on ou uso de coleiras específicas além da vacinação.
						 `,
					},
					{
						title: "Para que serve a vacina Puppy? Devo ou não utilizar? ",
						description: `Essa vacina foi desenvolvida para ser aplicada em filhotes de cães a partir de 30 dias de vida. Ela é uma bivalente (v2), combatendo parvovirose e cinomose. Ela é muito segura, garantindo que o filhote, comece a produzir anticorpos de proteção para essas duas 
						doenças graves. Filhotes, nessa fase, não devem ser vacinados com a v8 ou a v10 porque elas possuem outros antígenos que combatem outras doenças, e sua imunidade não estará preparada para produzir a proteção ideal.
						Sendo assim o filhote poderá desenvolver a doença se for vacinado com vacinas não indicadas para a idade. `,
					},
				],
			},
			contact: {
				success: "Contato enviado com sucesso!",
			},
			aboutUs: {
				description: `
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus id ullamcorper faucibus eget.
					Volutpat arcu accumsan blandit ante. Platea interdum quis porttitor amet maecenas purus non.
					Morbi feugiat ipsum, est nec in ultricies. Sodales pellentesque integer adipiscing turpis auctor
					integer elementum, enim. A lectus enim pretium nunc. Eu molestie dolor egestas commodo.
					Malesuada facilisis amet morbi massa praesent vitae. Senectus lacus aliquam vel condimentum
					consectetur. Sapien facilisi ac mauris at at nunc.
					Dolor et pellentesque pharetra dolor a risus. Feugiat praesent amet rhoncus tellus. Scelerisque
					ridiculus tempor, cursus cursus tristique dolor sodales. Hendrerit quis et commodo pulvinar. Nulla
					pharetra vitae erat quam sodales eget nam vitae nam. Est ultricies ac mi cursus cursus. Quis feugiat
					viverra molestie sapien, nulla nibh. Ullamcorper scelerisque convallis eu amet, vestibulum aliquet
					nunc ut. Faucibus ultrices vivamus semper quis leo. Amet, pulvinar adipiscing lacus dolor semper
					adipiscing libero volutpat egestas. Interdum odio etiam pulvinar sed.
					Non convallis orci tincidunt pretium. Tincidunt interdum purus tincidunt sit elementum.
				`,
				buttonText: "Fale com a nossa equipe",
			},
		},
		routeNames: {
			home: "Home",
			clinics: "Clínicas",
			ourTeam: "Nossa Equipe",
			androidDownload: "Baixar App para Android",
			iosDownload: "Baixar App para IOS",
			faq: "FAQ",
			blog: "Blog",
			logout: "Sair",
		},
		components: {
			emptyList: {
				title: "Sem resultados.",
			},
			navbar: {
				close: "Fechar",
			},
			cardPost: {
				readMore: "Ler mais",
			},
			contactForm: {
				title: "Fale com a gente!",
				name: "Nome",
				email: "E-mail",
				phone: "Telefone",
				message: "Mensagem",
				send: "Enviar",
				tip: "Entre em contato com a nossa Equipe e em breve nós responderemos.",
			},
			Footer: {
				text: "Desenvolvido pela",
				company: "Vet & Pet – Clínica Veterinária",
				cnpj: "CNPJ: 29.698.036/0001-72",
				email: "contato@vetpet24h.com.br",
				phoneNumbers: {
					phone: "(35) 3822-7268",
					secondPhone: "(37) 3112-0669",
					thirdPhone: "(32) 3518-0799",
				},
				downloadSection: "Disponível para android e IOS",
				socialLinks: {
					instagramLink: "https://www.instagram.com/vetepetveterinaria/",
					facebookLink: "https://pt-br.facebook.com/vetepet.gmail/",
				},
			},
			sectionAvailableClinics: {
				title: "Clínicas Disponíveis",
			},
			sectionClients: {
				title: "Depoimentos de Clientes",
			},
			contactButtons: {
				whatsapp: {
					number: "55373112-0669",
					encodedText: "Ol%C3%A1%2C+equipe+da+Vet e Pet!%21&app_absent=0",
				},
			},
			ourTeams: [
				{
					name: "Dra. Ana Karla de Lima Silma",
					description: "Graduada em Medicina Veterinária pela Universidade Federal de Lavras - MG 2012/2018. Residência em Clínica Médica de Animais de Companhia pela Universidade Federal de Lavras 2019-2021.",
					image: "/ana_karla.jpg",
				},
				{
					name: "Dra. Ana Lucinda Barcelos",
					description: `Graduada em Medicina Veterinária pela Universidade Federal 
					de Lavras – MG em 2017  
					Residência em Clínica Cirúrgica e Anestesiologia de Animais 
					de Companhia pela Universidade Federal de Lavras – 
					2018/2019.`,
					image: "/ana_lucinda.jpg",
				},
				{
					name: "Dra. Ana Luíza Alves Bezerra Gomes",
					description: `Graduada em Medicina Veterinária pela Faculdade Terra 
					Nordeste (FATENE) - CE em 2017  
					Pós Graduada em Clínica Cirúrgica de Pequenos Animais 
					pelo Instituto Quallitas/CE 2017 a 2019 
					Mestranda na área de Reprodução, Sanidade e Bem Estar 
					Animal - UNIFENAS)`,
					image: "/ana_luiza.jpg",
				},
				{
					name: "Dra. Caroline Marins Borges",
					description: `Graduado em Medicina Veterinária pela Universidade Federal de Lavras. Residência em Clínica Médica de Animais de Companhia. Membro da Associação Brasileira de
					Endocrinologia Veterinária`,
					image: "/caroline.jpg",
				},
				{
					name: "Dr. Diego Ribeiro",
					description: `Graduado em Medicina Veterinária pela
					Universidade Federalde Lavras-MG 2015-2019.
					Residência em Clínica Médica de Animais de
					Companhia pela Universidade Federal de
					Lavras 2020-2022.`,
					image: "/diego.jpg",
				},
				{
					name: "Dr. Fernando Marcos Rubim",
					description: `Graduado em Medicina Veterinária pela Universidade Federal 
					de Lavras- UFLA em 2019 
					Mestre  em  Ciências  Veterinárias,  área  de  concentração  em 
					fisiologia e metabolismo animal pela Universidade Federal de 
					Lavras – UFLA - 2021`,
					image: "/fernando.jpg",
				},
				{
					name: "Dra. Gabriela Rotatori Alvim",
					description: `Graduada em Medicina Veterinária pela
					Universidade Federal de Juiz de Fora-MG em 2019.
					Residência em Clínica Médica de
					Animais de Companhia pela Universidade Federal de Lavras 2020-2022`,
					image: "/gabriela.jpg",
				},
				{
					name: "Dra. Ingrid Santos da Fonseca",
					description: `Graduada em Medicina Veterinária pelo Universidade Federal 
					de Lavras - MG em 2021. 
					Pós Graduanda em Clínica Médica de Felinos pela Equalis  
					Membro-sócio da ABFel ( Academia Brasileira de Clínicos de 
					Felinos)`,
					image: "/ingrid.jpg",
				},
				{
					name: "Dra. Joyce Mendonça Vieira e Silva",
					description: `Graduada em Medicina Veterinária pela
					PUC Minas-Betim. Pós Graduação em Clínica Médica e Cirúrgica de Pequenos Animais no Instituto Quallitas`,
					image: "/joyce.jpg",
				},
				{
					name: "Dra. Kamila Ferreira de Araújo",
					description: `Graduada em Médica veterinária pelo Centro Universitário Una de Bom Despacho (2018)
					Ozonioterapeuta formada pela Instituição Brasileira de Ozônio e suas aplicações (2020)
					Graduanda em pós graduação de Clínica Médica de pequenos animais pela Centro de capacitação e excelência de ensino - Equalis (2021)`,
					image: "/kamila.jpg",
				},
				{
					name: "Dra. Lara Garcia",
					description: `Graduada em Medicina Veterinária pela Universidade Federal 
					de Juiz de Fora - MG em 2019  
					Residência em Clínica Médica de Animais de Companhia pela 
					Universidade Federal de Lavras 2020-2022) `,
					image: "/lara.jpg",
				},
				{
					name: "Dra. Lízia Resende Freire",
					description: `UFLA-Universidade Federal de Lavras.
					Residência em Clínica Médica de Animais de Companhia pela UFLA. Membro da Sociedade 
					Brasileira de Cardiologia Veterinária(SBCV) e da Sociedade Brasileira de Felinos(ABFel)`,
					image: "/lizia.jpg",
				},
				{
					name: "Dra. Lorena Lorraine Alves Furtado ",
					description: `Graduada em Medicina Veterinária pela Universidade Federal 
					de Lavras - UFLA em 2014 
					Residência  em  Clínica  e  Cirurgia  de  Cães  e  Gatos  pela 
					Universidade Federal de Viçosa – UFV – 2016 
					Mestre em Ciências Veterinárias com Ênfase em Cardiologia 
					Veterinária  pela  Universidade  Federal  de  Lavras  –  UFLA  – 
					2019  
					Membro da Sociedade Brasileira de Cardiologia Veterinária – 
					SBCV 
					`,
					image: "/lorena.jpg",
				},
				{
					name: "Dra. Marina Rabelo e Avelar ",
					description: `Graduada em Medicina Veterinária pela PUC Minas - 2015 
					Ultrassonografista pela Zootec - 2017 
					Pós Graduada em Cirurgia de Cães e Gatos pelo Qualittas - 
					2019 
					Pós  Graduanda  em  Ortopedia  e  Neurocirurgia  de  Cães  e 
					Gatos pela Anclivepa SP 
					Membro-sócia do Colégio Brasileiro de Cirurgia e 
					Anestesiologia Veterinária`,
					image: "/marina.jpg",
				},
			],
			clientsSections: [
				{
					name: "Tutor: Mariana Souza Alvarenga",
					pet: "Pet: Meg",
					image: "/Tutora_Mariana.jpg",
					rating: 5,
					description: `"A Vet e Pet é a melhor clínica veterinária de Lavras!!
					A atenção e o amor com que cuidam dos bichinhos é especial.
					Profissionais super capacitados que trabalham em equipe para o melhor atendimento.
					Tem uma infraestrutura preparada para atender qualquer situação. Recomendo de olhos fechados!”.`,
				},
				{
					name: "Tutor: Caroline Batista de Souza Pinto Reis",
					pet: "Pet: Vicky, Algodão, Pompom e Catita",
					image: "/Tutora_Carol.jpg",
					rating: 5,
					description: `"Temos um amor incondicional por nossos
					animais e todos são atendidos na Vet e Pet, pois sei
					que lá eles irão receber toda atenção e cuidado e
					sempre serão assistidos por profissionais competentes,
					dedicados e capacitados. Na minha opinião é, de longe,
					a melhor rede de clínica veterinária da região”.`,
				},
				{
					name: "Tutor: Elaine Cristina de Lima Rodrigues",
					pet: "Pet: Margot Helena e Charlotte Maria",
					image: "/person3.svg",
					rating: 5,
					description: `"A VetPet entrou nas nossas vidas num
					momento de aflição, e na hora do desespero, como não
					notar o carinho e competência que somos tratados?!
					Pais e pets!
					Isso fez com que nossos laços fossem estreitados.
					Novas aflições vieram,e a credibilidade aumentou mais
					ainda.
					Nada como ter segurança e confiança nos que cuidam
					de quem amamos.
					Obrigada a TODA equipe Vetpet, pois são vocês que
					fazem a excelência da melhor clínica veterinária da
					cidade.”`,
				},
			],
			initialSection: {
				title: "Vet & Pet",
				subTitle: "Nosso paciente merece esse cuidado",
				srcImage: "/initialSection.png",
				description: `Mudamos  o  conceito  e  a  relação  veterinário  x 
					paciente.  Aqui  o  bem-estar  do  animal  é  o  centro  de  nossas  ações, 
					dedicamos  nossos  esforços  por  uma  melhor  qualidade  nos  atendimentos 
					imbuídos em salvar as vidas dos pets. Investimos numa estrutura moderna, 
					com profissionais capacitados e especializados, afinal o seu pet merece.`,
				buttonText: "Fale com nossa equipe",
			},
		},
	},
});

export default strings;
