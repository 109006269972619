import * as API from "@startapp/connectvet-user-ssr-api";

import { LocalizedStringsMethods } from "localized-strings";

const productionUrl = "https://api.vetepet24h.com.br/user";
const stagingUrl = "https://api.vetepet24h.com.br/user-staging";

const bootAPI = (strings: LocalizedStringsMethods) => {
	const getAPIURL = () => {
		switch (process.env.APP_ENV) {
			case "production":
				return productionUrl;
			case "staging":
			default:
				return stagingUrl;
		}
	};

	API.setStrings(strings);
	API.setUrl(getAPIURL());
};

export default API;

export { bootAPI };
