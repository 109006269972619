export const colors = {
	primary: {
		50: "#FBF2EA",
		100: "#F3DAC4",
		200: "#EBC29E",
		300: "#E3AA78",
		400: "#DB9252",
		500: "#D37B2D",
		600: "#A96223",
		700: "#7E491B",
		800: "#543112",
		900: "#2A1809",
	},
	secondary: {
		50: "#EAF1FA",
		100: "#C5D8F2",
		200: "#A0BFE9",
		300: "#7BA6E0",
		400: "#558DD8",
		500: "#1D467D",
		600: "#1D467D",
		700: "#1D467D",
		800: "#132E53",
		900: "#0A1729",
	},
	gray: {
		100: "#F4F4F4",
		500: "#606060",
	},
};
